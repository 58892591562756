import React from 'react'
import ReactGA from 'react-ga';
import { Row, Col } from 'reactstrap';
import Layout from '../components/Layout';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initAnalytics } from '../initAnalytics';

export default class TemplatesPage extends React.Component {

  componentDidMount() {
    if (typeof window !== `undefined`) {
      initAnalytics();
      ReactGA.pageview('/templates');
    }
  }

  render() {

    return (
      <Layout>
        <Row>
          <Col className="text-center">
            <h1>Free Email Templates</h1>
            <h5>Customizable newsletter templates for media organizations</h5>
          </Col>
        </Row>
        <Row className="my-5 text-center justify-content-center border-top pt-5">
          <Col md="6">
            <h1><FontAwesomeIcon icon={faCode} /></h1>
            <p className="lead text-center"><a href="https://docs.google.com/document/d/1Xxx9usC0JJrFsNTzJh27Dn9jU_tL7YyID5zuJycIQ40/edit?usp=sharing">Read more here</a> and <a href="https://github.com/yellowbrim/newsletter-guide-templates">contribute on Github</a>.</p>
          </Col>
        </Row>
        <Row className="my-5 text-center">
          <Col>
            <h4 className="mb-3">Basic Email Template</h4>
            <a
              className="btn btn-crimson"
              href="/open-source-templates/simple-template.html.zip"
              download
              onClick={() => {
                ReactGA.event({
                  category: 'FreeTemplate',
                  action: 'Download',
                  label: '/open-source-templates/simple-template.html.zip'
                });
              }}
            >
Download
            </a>
            <br />
            <a
              href="/open-source-templates/mailchimp-simple.html.zip"
              className="mt-2 d-block"
              download
              onClick={() => {
                ReactGA.event({
                  category: 'FreeTemplate',
                  action: 'Download',
                  label: '/open-source-templates/mailchimp-simple.html.zip'
                });
              }}
            >
<small>Download for MailChimp</small>
            </a>
          </Col>
          <Col>
            <h4 className="mb-3">Newsletter Template</h4>
            <a
              className="btn btn-crimson"
              href="/open-source-templates/single-column-editorial.html.zip"
              download
              onClick={() => {
                ReactGA.event({
                  category: 'FreeTemplate',
                  action: 'Download',
                  label: '/open-source-templates/single-column-editorial.html.zip'
                });
              }}
            >
Download
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className="my-5" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
Templates created by
              {' '}
              <a
                href="https://yellowbrim.com/?ref=freeTemplates"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: 'AuthorBrandInteraction',
                    action: 'Click',
                    label: 'YellowBrim'
                  });
                }}
              >
Yellow Brim

              </a>
              {' '}
in partnership with
              {' '}
              <a
                href="https://shorensteincenter.org/?ref=freeTemplates"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: 'AuthorBrandInteraction',
                    action: 'Click',
                    label: 'Harvard'
                  });
                }}
              >
Harvard Shorenstein Center

              </a>
              {' '}
and
              {' '}
              <a
                href="https://www.lenfestinstitute.org/?ref=freeTemplates"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: 'AuthorBrandInteraction',
                    action: 'Click',
                    label: 'Lenfest'
                  });
                }}
              >
The Lenfest Institute

              </a>
.

            </p>
          </Col>
        </Row>

      </Layout>
    )
  }
}

TemplatesPage.propTypes = {
  // data: PropTypes.shape({
  //   allWordpressPost: PropTypes.shape({
  //     edges: PropTypes.array,
  //   }),
  // }),
}
